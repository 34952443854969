<template>
  <base-material-card inline class="mb-0" icon="mdi-compare" :title="$t('Comparație înregistrări')">
    <v-row dense>
      <v-col cols="12" sm="4" md="3" lg="2">
        <v-navigation-drawer permanent>
          <v-list dense nav class="pl-1">
            <v-list-item-group v-model="prefix" mandatory color="primary">
              <template v-for="pre in prefixes">
                <v-list-item :key="pre.value" dense>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(pre.text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-divider v-if="index < reports.length - 1" :key="index"></v-divider> -->
              </template>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="12" sm="8" md="9" lg="10">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field v-model="titlea" :label="$t('Prima înregistrare')" readonly hide-details clearable>
              <template #append>
                <v-icon class="pt-1" tabindex="-1" @click.prevent="searchRelation('a')">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="titleb" :label="$t('A doua înregistrare')" readonly hide-details clearable>
              <template #append>
                <v-icon class="pt-1" tabindex="-1" @click.prevent="searchRelation('b')">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              ref="table"
              dense
              fixed-header
              :server-items-length="2000"
              :headers="headers"
              :items="records"
              hide-default-footer
            >
              <template #item.val="props">
                <span v-if="props.item.val && !props.item.val2" class="green--text"> {{ props.item.val }} </span>
                <span v-else-if="props.item.val && props.item.val != props.item.val2" class="orange--text">
                  {{ props.item.val }}
                </span>
                <span v-else> {{ props.item.val }} </span>
              </template>
              <template #item.val2="props">
                <span v-if="props.item.val2 && !props.item.val" class="red--text"> {{ props.item.val2 }} </span>
                <span v-else-if="props.item.val2 && props.item.val != props.item.val2" class="orange--text">
                  {{ props.item.val2 }}
                </span>
                <span v-else> {{ props.item.val2 }} </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="searchSelected" />
  </base-material-card>
</template>
<script>
import SearchLink from "../../../components/containers/SearchLink"
export default {
  components: {
    SearchLink
  },
  data: () => ({
    titlea: "",
    titleb: "",
    search: "",
    records: [],
    fieldSchema: {},
    resource: {},
    resourceCompare: {},
    collection: "ebib_marc_cln",
    prefix: 0,
    headers: [
      { text: "Nume câmp", value: "nam", align: "left", type: "String", width: "30%" },
      { text: "Câmp", value: "field", align: "left", type: "String", width: "95px" },
      { text: "Prima înregistrare", value: "val", align: "left", type: "String", width: "35%" },
      { text: "A doua înregistrare", value: "val2", align: "left", type: "String", width: "35%" }
    ],
    prefixes: [
      { text: "Utilizatori", value: "cln" },
      { text: "Monografii", value: "mon" },
      { text: "Inventare", value: "inv" },
      { text: "Articole", value: "art" },
      { text: "Autorități", value: "ath" },
      { text: "Evenimente", value: "evn" },
      { text: "Furnizori", value: "prv" },
      { text: "RMF", value: "rmf" }
    ]
  }),
  computed: {
    appSchema() {
      return this.$store.getters.appSchema
    }
  },
  watch: {
    prefix() {
      this.$log("prefix ", this.prefixes[this.prefix].value)
      this.collection = "ebib_marc_" + this.prefixes[this.prefix].value
      const tables = this.appSchema.filter(itm => itm.collection == "marc_" + this.prefixes[this.prefix].value)
      this.fieldSchema = {}
      this.generateListFields(tables[0].content, "")
      this.titlea = ""
      this.titleb = ""
    },
    titlea() {
      if (this.titlea && this.titleb) {
        this.compareRecords()
      } else {
        this.records = []
      }
    },
    titleb() {
      if (this.titlea && this.titleb) {
        this.compareRecords()
      } else {
        this.records = []
      }
    }
  },
  mounted() {
    const tables = this.appSchema.filter(itm => itm.collection == "marc_cln")
    this.generateListFields(tables[0].content, "")
  },
  methods: {
    searchRelation(e) {
      this.search = e
      this.$refs.searchLink.open(this.collection, null, "compare")
    },
    searchSelected(e) {
      this.$log("searchSelected ", e)
      let tit = this.resolvePath("fields.299.0.a.0.val", e)
      if (!tit) {
        tit = this.resolvePath("fields.200.0.a.0.val", e)
      }
      if (this.search == "a") {
        this.resource = e
        this.titlea = tit
      } else {
        this.resourceCompare = e
        this.titleb = tit
      }
      if (this.titlea && this.titleb) {
        this.compareRecords()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    compareRecords() {
      this.records = []
      for (const fld in this.resource.fields) {
        for (const flds in this.resource.fields[fld]) {
          let bool = false
          const fd = { fld: fld, field: fld, ordf: flds, sub: "", val: "", ord: this.resource.fields[fld][flds].ord }
          const fdl = { fld: fld, field: fld, ordf: flds }
          if (this.resource.fields[fld][flds].link) {
            fdl.sub = "link"
            fdl.nam = "link"
            fdl.val = this.resource.fields[fld][flds].link
          }
          if (this.resourceCompare.fields[fld]) {
            if (this.resourceCompare.fields[fld][flds]) {
              bool = true
              fd.sub2 = ""
              fd.val2 = ""
              fd.ord2 = this.resourceCompare.fields[fld][flds].ord
              if (this.resourceCompare.fields[fld][flds].link) {
                fdl.val2 = this.resourceCompare.fields[fld][flds].link
              }
            }
          }
          this.records.push(fd)
          if (fdl.val || fdl.val2) {
            this.records.push(fdl)
          }
          for (const sub in this.resource.fields[fld][flds]) {
            if (sub.length == 1) {
              for (const subs in this.resource.fields[fld][flds][sub]) {
                let nam = fld + sub
                if (this.fieldSchema["fields." + fld + "." + sub + ".val"]) {
                  nam = this.fieldSchema["fields." + fld + "." + sub + ".val"].flbl
                }
                const sb = {
                  fld: fld,
                  field: fld + sub,
                  ordf: flds,
                  sub: sub,
                  nam: nam,
                  val: this.resource.fields[fld][flds][sub][subs].val,
                  ord: this.resource.fields[fld][flds][sub][subs].ord
                }
                if (bool) {
                  if (this.resourceCompare.fields[fld][flds][sub]) {
                    if (this.resourceCompare.fields[fld][flds][sub][subs]) {
                      sb.val2 = this.resourceCompare.fields[fld][flds][sub][subs].val
                      sb.ord2 = this.resourceCompare.fields[fld][flds][sub][subs].ord
                    }
                  }
                }
                this.records.push(sb)
              }
            }
          }
          if (bool) {
            for (const sub in this.resourceCompare.fields[fld][flds]) {
              if (sub.length == 1) {
                for (const subs in this.resourceCompare.fields[fld][flds][sub]) {
                  let bool2 = false
                  if (!this.resource.fields[fld][flds][sub]) {
                    bool2 = true
                  } else {
                    if (!this.resource.fields[fld][flds][sub][subs]) {
                      bool2 = true
                    }
                  }
                  if (bool2) {
                    let nam = fld + sub
                    if (this.fieldSchema["fields." + fld + "." + sub + ".val"]) {
                      nam = this.fieldSchema["fields." + fld + "." + sub + ".val"].flbl
                    }
                    this.records.push({
                      fld: fld,
                      field: fld + sub,
                      ordf: flds,
                      sub: sub,
                      nam: nam,
                      val2: this.resourceCompare.fields[fld][flds][sub][subs].val,
                      ord2: this.resourceCompare.fields[fld][flds][sub][subs].ord
                    })
                  }
                }
              }
            }
          }
        }
      }

      for (const fld in this.resourceCompare.fields) {
        for (const flds in this.resourceCompare.fields[fld]) {
          let bool = false
          if (!this.resource.fields[fld]) {
            bool = true
          } else {
            if (!this.resource.fields[fld][flds]) {
              bool = true
            }
          }
          if (bool) {
            this.records.push({
              fld: fld,
              field: fld,
              ordf: flds,
              sub: "",
              val2: "",
              ord2: this.resourceCompare.fields[fld][flds].ord
            })
            if (this.resourceCompare.fields[fld][flds].link) {
              this.records.push({
                fld: fld,
                field: fld,
                nam: "link",
                ordf: flds,
                sub: "link",
                val2: this.resourceCompare.fields[fld][flds].link
              })
            }
            for (const sub in this.resourceCompare.fields[fld][flds]) {
              if (sub.length == 1) {
                for (const subs in this.resourceCompare.fields[fld][flds][sub]) {
                  let nam = fld + sub
                  if (this.fieldSchema["fields." + fld + "." + sub + ".val"]) {
                    nam = this.fieldSchema["fields." + fld + "." + sub + ".val"].flbl
                  }
                  const sb = {
                    fld: fld,
                    field: fld + sub,
                    ordf: flds,
                    sub: sub,
                    nam: nam,
                    val2: this.resourceCompare.fields[fld][flds][sub][subs].val,
                    ord2: this.resourceCompare.fields[fld][flds][sub][subs].ord
                  }
                  this.records.push(sb)
                }
              }
            }
          }
        }
      }
      this.records.sort((a, b) => {
        return Number(a.fld) - Number(b.fld) || Number(a.ordf) - Number(b.ordf) || a.sub.localeCompare(b.sub)
      })
    },
    generateListFields(rec, name) {
      //this.$log("view type ", this.viewType)
      for (const prop in rec) {
        if ((prop === "type" || prop === "schema") && rec.type && rec.schema) {
          if (prop === "type") {
            this.generateListFields(rec.schema, name)
          }
        } else {
          let newname = ""
          if (name) {
            newname = name + "." + prop
          } else {
            newname = prop
          }
          if (rec[prop].schema) {
            if (rec[prop].type === "list" && rec[prop].schema.type != "dict") {
              this.fieldSchema[newname] = rec[prop].schema
            }
            this.generateListFields(rec[prop].schema, newname)
          } else {
            if (rec[prop].type) {
              if (
                prop != "ctg" &&
                prop != "id" &&
                prop != "company" &&
                prop != "ord" &&
                prop != "link" &&
                prop != "src_id"
              ) {
                let lbl = newname
                if (rec[prop].meta) {
                  lbl = rec[prop].meta.label
                }
                this.fieldSchema[newname] = rec[prop]
                this.fieldSchema[newname].flbl = lbl
              }
            }
          }
        }
      }
    }
  }
}
</script>
